/* You can add global styles to this file, and also import other style files */
@import "reset.css";
body {
  display: flex;
  flex-flow: column wrap;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: Verdana,Geneva,Arial,Helvetica,sans-serif;
}
H1 {
  font-family: Arial;
  font-size: 12pt;
  color: Navy;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin: 20px auto 20px auto;
}

H2 {
  font-family: Arial;
  font-size: 12pt;
  color: Navy;
  font-weight: bold;
  background-color: white;
  margin-top: 20px;
}

H3 {
  font-family: Arial;
  font-size: 10pt;
  color: black;
  font-weight: bold;
  background-color: white;
  border-right: 140px solid silver;
  height: 20px;
  margin-bottom: 20px;
}

H4 {
  font-family: Arial;
  font-size: 12pt;
  color: Black;
  font-weight: bold;
  background-color: white;
  margin-bottom: 20px;
}

h5 {
  font-family: Arial;
  font-size: 12pt;
  color: Black;
  font-weight: bold;
  background-color: white;
  margin-bottom: 20px;
  text-align: center;
}

html, body {
  height: 100%;
  /*background-color: #4b6480;*/
  color: black;
}

h2 {
  width: 100%;
  border-bottom: 1px solid #003399;
}

h2 + h3 {
  font-size: 12px;
  margin: 0px 0 20px 0;
  padding: 1px 0 0px 0;
  background: transparent;
}

h4 + h4 {
  margin: -15px 15px 20px 15px;
  color: navy;
}

li {
  padding: 2px 0;
}
a {
  color: #3651ba;
  text-decoration: none;
}
pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.428571429;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
}
blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    border-left: 5px solid #eee;
}
kbd {
    display: inline-block;
    margin: .1em;
    padding: .1em .6em;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 11px;
    line-height: 1.4;
    color: #242729;
    text-shadow: 0 1px 0 #fff;
    border-color: #e1e3e5;
    border: 1px solid #abd3b9;
    border-radius: 3px;
    box-shadow: 0 1px 0 #0c0d0e33, inset 0 0 0 2px #fff;
    white-space: nowrap;
}
code, kbd, pre, samp {
    font-family: monospace,serif;
    font-size: 1em;
}
#main {
  display: flex;
  flex-flow: row wrap;
}
#main > div:first-child {
  flex: 1;
}
#main > div:nth-child(2) {
  flex: 2;
}
#main > div:nth-child(3) {
  flex: 1;
}

P {
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-align: justify;
  padding: 5px 0px 10px 0px;
  color: Black;
}

button {
  background: linear-gradient(to bottom,#bfd5ea,#6a9dd0);
  padding: 6px 10px;
  border: none;
}
button:disabled {
  background: linear-gradient(to bottom, #bfd5ea, #bfd5ea);
}
input {
  padding: 6px 5px;
}

pre {    
  font-family: "Courier New",Monaco,Menlo,Consolas,monospace;
  display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.428571429;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
}    

.web {
  padding: 20px;
  border: 1px solid #555555;
  background: #cce4f3;
  border-radius: 10px;
  margin-bottom: 20px;
}

.web div:first-child {
  font-size: 18px;
  font-weight: bold;
}

.web div:nth-child(2) {
  font-size: 12px;
  font-weight: bold;
}
.syntaxIdent
{
  color: teal;
}
.syntaxReserve
{
  color: black;
  font-weight: bold;
}
.syntaxString
{
  color: blue;
}
.syntaxNumber
{
  color: maroon;
}
.syntaxComment, .syntaxComment pre
{
  color: navy;
  background: #DDDDDD;
}
.syntaxFunction
{
  color: #FF00FF;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}
.down {
  transform: rotate(45deg);
}
.up {
  transform: rotate(-135deg);
}
.note {
    color: red;
}
span {
  color: red;
}
#statusMessage {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 5px 3px;
}

#statusMessage img {
  vertical-align: middle;
  margin-right: 5px;
}